.route-details {
    background-color: #ffff;
    padding: 56px 0 0;

    .container {
        max-width: 1230px;

        h2 {
            color: #1E1E26;
            font-size: 24px;
            font-weight: 700;
            padding-left: 10px;
            margin-bottom: 32px;
            margin-bottom: 0;

            @media(max-width:767px) {
                    width: 100%;
                    font-size: 18px;
                    img{
                        width: 30px;
                    }
                }
            }

            .search-inputs-container-sub-div-distance{
                display: flex;
                width:40%;

                @media screen and (max-width: 991px) {
                    width: auto;
                }
                
                
                .flip {
                    position: relative;
                    right: 0;
                    width: 56px;
                    cursor: pointer;
                    z-index: 10;
                    margin: 0px -14px;
                  }
            
                //   .map_pin {
                //     position: relative;
                //     width: 24px;
                //     left: calc(50% + 48px);
                //     z-index:10;
                //   }
            
                //   .circle {
                //     position: absolute;
                //     width: 24px;
                //     left: 24px;
                //     height: 30px;
                //     top: 15px;
                //     z-index:10;
                //   }
                .source-input-bar{
                    width: 46%;
                    height: 56px;
                    border-radius: 16px;
                    position: relative;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    .location {
                      position: absolute;
                      //left: 15px;
                      width: 25px;
                      font-family: 'Open Sans', sans-serif;
                      font-style: normal;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      color: #1E1E26;
                      left:15px;
                      top:15px;
                    }
                    
                    input{
                        width: 100%;
                        height: 100%;
                        border-radius: 16px;
                        padding-left: 25%;
                    }
                    .search-suggestion-wrapper {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        border-radius: 12px;
                        background-color: white;
                        padding: 24px 24px 0;
                        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
                        width: 100%;
                        max-height: 291px;
                        height: fit-content;
                        overflow-y: auto;
                        z-index: 99;
                        scrollbar-width: thin;
                        scrollbar-color: #22BBB0 #f1f1f1;
                      }

                }
                .destination-input-bar{
                  border: 1px solid #0000001a;
                    position: relative;
                    width: 46%;
                    height: 56px;
                    border-radius: 16px;
                    .location {
                      position: absolute;
                      //left: 15px;
                      width: 25px;
                      font-family: 'Open Sans', sans-serif;
                      font-style: normal;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      color: #1E1E26;
                      left:15px;
                      top:15px;
                    }
                    input{
                        width: 100%;
                        height: 100%;
                        border-radius: 16px;
                        padding-left: 25%;
                    }
                    .search-suggestion-wrapper {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        border-radius: 12px;
                        background-color: white;
                        padding: 24px 24px 0;
                        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
                        width: 100%;
                        max-height: 291px;
                        height: fit-content;
                        overflow-y: auto;
                        z-index: 99;
                        scrollbar-width: thin;
                        scrollbar-color: #22BBB0 #f1f1f1;
                      }

                }
                
            }

        ul {
            padding: 40px 71px;
            list-style: none;
            margin: 0 0 50px;
            border: 1px solid #E8E8E9;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
            border-radius: 16px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            text-align: center;

            @media(max-width:767px) {
                padding: 40px 15px;
                flex-direction: column;
                gap: 30px
            }

            li {
                width: 25%;
                border-right: 1px solid #d2d2d4;
                padding: 0 12px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 20px;

                p {
                    font-weight: 600;
                    font-family: 'Open Sans';
                    font-size: 16px;
                    color: #1E1E26;
                    margin-bottom: 0;
                }

                h3 {
                    font-size: 20px;
                    font-weight: 700;
                    font-family: 'Ubuntu';
                    color: #1E1E26;
                    margin-bottom: 0;
                }

                &:nth-child(4n+4) {
                    border-right: none;
                }

                @media(max-width:767px){
                    width: 100%;
            
                        border-right: none;
                        border-left: none;
                }
                

            }
        }
    }
}