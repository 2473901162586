@import '../../assets/styles/font_color_mixins';
@import "_variable.scss";

.sitemap{
    .main-content{
        @media screen and (min-width: 1024px) {
          width: 89%;
          margin:auto;
          margin-top: 10%;
          
        }
      }
}