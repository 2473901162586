@import './font_color_mixins';

.icon-40 {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.icon-48 {
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.icon-24 {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.icon-20 {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.icon-18 {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.icon-16 {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.icon-6 {
  width: 6px;
  height: 6px;
}

.icon-32 {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.active-radio {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 4px;
  border: 1.5px solid #A5A5A8;

  div {
    border-radius: 100%;
    background-color: $teal-22BBB0;
    margin: auto;
    width: 100% !important;
    height: 100% !important;

  }
}

.inactive-radio {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1.5px solid #A5A5A8;
}

.cursor-pointer {
  cursor: pointer
}

.cursor-disable{
  cursor: not-allowed;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible !important;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}
.margin-bottom-150{
  margin-bottom: 150px;

}

.margin-bottom-64 {
  margin-bottom: 64px;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.flex-wrap-12 {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.gap-4 {
  gap: 4px;
}

.gap-12 {
  gap: 12px;
}

.gap-18 {
  gap: 18px;
}

.flex-wrap-16 {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.flex-wrap-20 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.gap-14 {
  gap: 14px;
}

.gap-24 {
  gap: 24px;
}

.common-input-wrap {
  border: 1px solid #D2D2D4;
  border-radius: 8px;
  padding: 16px;
  overflow: hidden;
}

.common-input-wrap:focus-within {
  border: 1px solid $teal-22BBB0 !important;
  background: #F0FAF9;
  border-radius: 8px;
  padding: 16px;
  overflow: hidden;

  input {
    background: transparent;
  }
}

.common-input-wrap-error {
  background: #F8F8F9;
  border: 1.5px solid #F15252;
  border-radius: 8px;
  position: relative;
  overflow-y: visible !important;
  overflow-x: hidden;

  input {
    background: transparent;
  }

}

.common-input-wrap-error:first-of-type + div {

  p {
    color: #F15252;
  }
}

.vertical-divider {
  height: 24px;
  width: 1px;
  background-color: #D2D2D4;
}

.w-fit-content {
  width: fit-content !important;
}

.w-lg-50 {
  @media screen and (max-width: 1024px) {
    width: 100% !important;
  }
  @media screen and (min-width: 1024px) {
    width: 50% !important;
  }
}

.h-fit-content {
  height: fit-content !important;
}

.h-100 {
  height: 100% !important;
}

.settle-at-bottom {
  //width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 100000;
}

.left-to-right {
  left: 0;
  right: 0;
}

.absolute-horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-12 {
  border-radius: 12px;
}

.border-radius-16 {
  border-radius: 16px;
}

.border-less-input,
.border-less-input:active,
.border-less-input:focus {
  border: none;
  outline: none;
  background-color: transparent;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.height-100vh {
  height: 100vh;
}

.height-95vh {
  height: 95vh;
}

.height-90vh {
  height: 90vh;
}

.height-80vh {
  height: 80vh;
}

.height-75vh {
  height: 75vh;
}

.height-70vh {
  height: 70vh;
}

.padding-top-side-40 {
  padding: 40px 40px 0 40px;
}

.horizontal-scroller {
  display: flex;
  overflow-x: auto;
}

.horizontal-scroller {
  //overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  //height: 656px;
}

.horizontal-scroller::-webkit-scrollbar {
  display: none;
}

.three-line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.curved-border-grey-24 {
  border: 1px solid #D2D2D4;
  border-radius: 24px;
  //margin:16px;
  width: 100%
}

.dot-seperator {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: $grey-53565A;
}

.bottom-dark-fold {
  background: #1E1E26;
  padding: 24px 0 24px 0;
  box-shadow: 0px -4px 14px rgba(0, 0, 0, 0.08);
  border-radius: 16px 16px 0px 0px;
  z-index: 100000;
}

.white-fold {
  background-color: white !important;
  //overflow: hidden;

  @media screen and (max-width: 1023px) {
    margin-top: -16px;
    border-radius: 20px 20px 0px 0px;
  }
  @media screen and (min-width: 1024px) {
    margin-top: -24px;
    border-radius: 32px 32px 0px 0px;
  }

}

.calendar-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99 !important;

  .react-calendar {

    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    padding: 24px 16px;
    //border: 1px solid $teal-22BBB0;
    margin-top: 16px;
    max-width: 300px;

    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__prev2-button {
      display: none;
    }

    .react-calendar__navigation {
      display: flex;

      margin-bottom: 20px;

      button {
        background: transparent;
        border: none;
        outline: none;
        color: #1E1E26;

      }
    }

    .react-calendar__navigation__label__labelText--from {
      color: #1E1E26;
    }

    .react-calendar__navigation__label__labelText--from::after {
      content: '';
      transform: rotate(90deg);
      color: $teal-22BBB0;
      margin-left: 4px;
    }

    .react-calendar__month-view__weekdays__weekday {
      color: $grey-53565A;
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      abbr {
        text-align: center;
        text-decoration: none;
      }
    }

    .react-calendar__month-view__weekdays {
      padding: 16px 0;
      margin-bottom: 16px;
      border-bottom: 1px solid #E8E8E9;
    }

    .react-calendar__month-view__weekdays__weekday::first-letter {

    }

    .react-calendar__month-view__weekdays__weekday :not(::first-letter) {

      color: transparent;
    }

    .react-calendar__tile {
      background-color: transparent;
      border: 1px solid transparent;
      outline: none;
      color: #6C6C6C;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      padding: 4px;

      & :active,
      & :focus,
      &:hover {
        background-color: $teal-22BBB0;
        border-radius: 100%;
        color: #FFFFFF;
      }

      &:disabled {
        color: grey;
        opacity: 80%;
      }
    }

    .react-calendar__tile--rangeStart {
      background-color: $teal-22BBB0;
      border-radius: 100%;
      color: #FFFFFF;
    }

    .react-calendar__viewContainer {
      border-radius: 8px;
    }
  }
}

.filter-option-box {
  //position: absolute;
  //top: 85%;
  //padding: 8px;
  margin-top: 16px;
  //border-radius: 4px;
  //left: 0;
  //z-index: 100 !important;
  background: #FFFFFF;
  //box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  max-height: 300px;
  overflow-y: auto;
}

.filter-option-box::-webkit-scrollbar {
  width: 4px;
}

.filter-option-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.filter-option-box::-webkit-scrollbar-thumb {
  background:  #E8E8E9;
}

.filter-option-box::-webkit-scrollbar-thumb:hover {
  background: #E8E8E9;
}

.submit-button {
  background-color: $teal-22BBB0;
  color: white;
  border-radius: 100px;
  padding: 16px;
}

.submit-button:disabled {
  background-color: #22BBB090 !important;
  color: #FFFFFF;
  cursor: not-allowed !important;
}

.green-outline-button {
  background: #FFFFFF;
  border: 1px solid #22BBB0 !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  padding: 8px 16px;
}

.layer-item-wrap a {
  position: absolute;
  right: 4px;
  bottom: 23px;
  background-color: black !important;
  border-radius: 12px;

}

.map-zoom-in {
  position: absolute;
  right: 48px;
  bottom: 16px;
  background-color: black !important;
  color: white !important;
  border-radius: 12px;
}

#geo_location {
  display: none;
}

.map-control {
  top: auto !important;
  bottom: 0 !important;
}

#attr {
  display: none;
}

.map-zoom-out {
  position: absolute;
  right: 72px;
  bottom: 16px;
  border: 2px solid $grey-53565A;
  background: #FFFFFF !important;
  border-radius: 12px;
  color: black !important;
  line-height: 24px !important;
  margin-right: 16px;

}

.box-shadow-1 {
  box-shadow: 0px 4px 12px 0px #00000014;
}

.active-tab {
  background: #F0FAF9;
  border-bottom: 3px solid #22BBB0;
  color: #22BBB0;
  padding: 18px;
  cursor: pointer;
}

.in-active-tab {
  padding: 18px;
  border-bottom: 1px solid #E8E8E9;
  color: #A5A5A8;
  cursor: pointer;
}

.white-bg {
  background-color: white;
}

// font color
.white-color {
  color: white
}

.yellow-f8ec4e-color {
  color: $yellow-F8EC4E;
}

.brown-F1D3B7-color {
  color: $brown-F1D3B7;
}

.blue-light-F2FAF9-color {
  color: $blue-light-F2FAF9;
}

.green-medium-C3EBEA-color {
  color: $green-medium-C3EBEA;
}

.teal-22BBB0-color {
  color: $teal-22BBB0;
}

.grey-53565A-color {
  color: #53565A;
}

.red-F85959-color {
  color: #F85959
}

.black-1E1E26-color {
  color: #1E1E26;
}

.black-1E1E26-bg {
  background-color: #1E1E26;
}

.light-green-3AC070-color {
  color: #3AC070;
}

.teal-2-00A095-color {
  color: #00A095;
}

.grey-2-78787d-color {
  color: $gray-78787d
}

.grey-3-adadad-color {
  color: #ADADAD;
}

// font styles

//ubuntu
.ubuntu-400w-14s-28h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
}

.ubuntu-400w-16s-28h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.ubuntu-400w-20s-32h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

.ubuntu-500w-18s-28h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.ubuntu-500w-12s-20h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.ubuntu-500w-14s-22h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.ubuntu-500w-16s-28h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}

.ubuntu-500w-18s-28h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.ubuntu-500w-20s-32h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}

.ubuntu-700w-14s-22h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.ubuntu-700w-16s-24h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.ubuntu-700w-18s-22h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.ubuntu-700w-18s-28h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.ubuntu-700w-20s-32h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

.ubuntu-700w-24s-32h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.ubuntu-700w-24s-36h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}

.ubuntu-700w-32s-48h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
}

.ubuntu-700w-40s-64h {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 64px;
}

//open sans
.open-400w-10s-14h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}
.open-400w-12s-18h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.open-400w-12s-20h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.open-400w-16s-22h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.open-400w-16s-24h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.open-400w-18s-28h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.open-400w-14s-22h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.open-400w-14s-24h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.open-600w-9s-18h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 18px;
}

.open-600w-11s-18h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
}

.open-600w-12s-18h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.open-600w-14s-24h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.open-600w-18s-24h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.open-500w-12s-18h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.open-500w-18s-28h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.open-600w-9s-18h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 18px;
}

.open-600w-12s-20h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.open-600w-14s-22h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.open-600w-16s-24h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.open-600w-18s-28h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.open-700w-12s-18h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.open-700w-14s-22h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.open-700w-14s-24h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

.open-700w-16s-22h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

.open-700w-16s-24h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.open-700w-18s-28h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.open-700w-20s-31h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 31px;
}

.open-700w-24s-36h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}

.open-700w-32s-48h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
}

.open-600w-10s-24h {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 10px;
  font-weight: 600;
  line-height: normal; 
}

.font-700{
  font-weight: 700;
}

@media screen and (min-width: 1023px) {
  .global-container {
    max-width: 1024px;
    width: 100%;
    margin: auto;

  }
}

@media screen and (max-width: 1023px) {
  .global-container {
    max-width: 786px;
    margin: auto;
  }
}

.outline-button-white-border {
  background: transparent;
  border: 1.5px solid white;
  border-radius: 40px;
  padding: 10px 16px;
  height: fit-content;
}

.secondary-button-teal {
  background: #FFFFFF;
  border: 1.5px solid #22BBB0 !important;
  border-radius: 40px;
  padding: 10px 16px;
  height: fit-content;
}

.primary-button-teal {
  background: #22BBB0;
  border-radius: 100px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

s {
  text-decoration-line: line-through;
  text-decoration-thickness: 1.5px;
  @media screen and (max-width: 1024px) {
    text-decoration-color: #A8A8A8;
    
  }
  text-decoration-color: #F15252;
}

.yellow-pill {
  background: #F8EC4E;
  border-radius: 20px;
  padding: 4px 8px;
}

.custom-accordion {
  border: 1px solid #D2D2D4;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 24px;

  //.content {
  //  padding: 16px 24px;
  //}

  .header-active {
    display: flex;
    justify-content: space-between;
    background: #F8F8F9;
    font-family: 'Open Sans', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #D2D2D4;
    padding: 16px 24px;

    p {
      margin-bottom: 0;
    }

    /* identical to box height, or 150% */

    /* Black / 100 */

    color: #1E1E26;

  }

  .header {
    display: flex;
    justify-content: space-between;
    font-family: 'Open Sans', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 16px 24px;

    p {
      margin-bottom: 0;
    }

    /* identical to box height, or 150% */

    /* Black / 100 */

    color: #1E1E26;

  }

  .active-content{
    display: block;
    opacity: 1;
    height: auto;
    transition: all 0.5s 0.04, 0.62, 0.23, 0.98;
  }

  .inactive-content{
    display:none;
    opacity: 0;
    height: 0;
    transition: all 0.5s 0.04, 0.62, 0.23, 0.98;
  }
}

.luggage-size-illustration {
  width: 100%;
  height: 170px;
  object-fit: contain;
}

.mobile-section-divider {
  height: 6px;
  background: #F4F4F4;
  width: 100%;
}

.story-image {
  border-radius: 100% !important;

  @media screen and (max-width: 1024px) {
    width: 60px;
    height: 60px;
    object-fit: contain;

    border: 2px solid WHITE !important;
    outline: 2px solid #00A095;
  }
  @media screen and (min-width: 1024px) {
    width: 90px;
    height: 90px;
    max-width: 45vw;
    object-fit: contain;

    border: 2px solid transparent !important;
    outline: 2px solid #00A095;
  }
}

.top-neg-40 {
  top: -40px !important;
}