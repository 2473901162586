@import '../../assets/styles/font_color_mixins';
@import "_variable.scss";

.no-route{
    .main-content{
        @media screen and (min-width: 1024px) {
          width: 100%;
          margin:auto;
          margin-top: 10%;
          
        }

        .no-route-container{
           
          @media screen and (max-width:991px){
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 89%;
            margin: auto;
            margin-bottom: 20px;
            }
            .page-not-found-message{
               

                @media screen and (min-width:992px){
                position: relative;
                width: 100%;
                height: 350px; 
                }

                @media screen and (max-width:991px){
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    width: 100%;
                    height: auto;
                    }
                .page-not-found-message-data{
                    padding: 32px 80px 72px 80px;
                    position: relative;
                    z-index: 2;
                    
                    @media screen and (max-width:768px){
                        padding: 32px 16px;
                        }
                    .heading-teal{
                        font-family: 'Open Sans', sans-serif;
                        font-size: 40px;
                        font-weight: 700;
                        line-height: 50px;
                        text-align: left;
                        color: #00A095;
                        @media screen and (max-width:768px){
                            font-size: 32px;
                            font-weight: 700;
                            line-height: 48px;
                        
                            }
                    }
                    .desc{
                        width: 40%;
                        font-family: 'Open Sans',sans-serif;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 28px;
                        text-align: left;
                        color: #4B4B51;

                        @media screen and (max-width:768px){
                            width: 80%;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            }
                    }
                    
                }
                .image-no-route{
                    position: absolute;
                    width: 717px;
                    height: 337px;
                    bottom: 0;
                    right: 0;
                    
                    

                @media screen and (min-width:992px){
                    position: absolute;
                    width: 717px;
                    height: 337px;
                    bottom: 0;
                    right: 0;
                    z-index: 1;
                    }
    
                @media screen and (max-width:991px){
                    position: relative;
                    width: 100%;
                    height: 168px;
                    }

                }

        
            }

            .innerSubdiv{
              width: 89%;
              height: 100%;
              margin: 50px auto;
              display: flex;
              flex-direction: row;
              gap: 5%;
              border-radius:12px;
              border:1px solid #D2D2D4;
  
              .search-inputs-container{
                  display: flex;
                  width: 100%;
                  flex-direction: row;
                  gap: 15px;
                  padding: 30px 0 30px 30px;
                  align-items: center;
                  .search-inputs-container-sub-div{
                      display: flex;
                      width:50%;
              
                      .flip {
                          position: relative;
                          right: 0;
                          width: 64px;
                          cursor: pointer;
                          z-index: 10;
                          margin: 0px -14px;
                        }
                  
                      //   .map_pin {
                      //     position: relative;
                      //     width: 24px;
                      //     left: calc(50% + 48px);
                      //     z-index:10;
                      //   }
                  
                      //   .circle {
                      //     position: absolute;
                      //     width: 24px;
                      //     left: 24px;
                      //     height: 30px;
                      //     top: 15px;
                      //     z-index:10;
                      //   }
                      .source-input-bar{
                          width: 46%;
                          height: 60px;
                          border-radius: 16px;
                          position: relative;
                          border: 1px solid rgba(0, 0, 0, 0.1);
                          .location {
                            position: absolute;
                            //left: 15px;
                            width: 25px;
                            font-family: 'Open Sans', sans-serif;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: #1E1E26;
                            left:15px;
                            top:15px;
                          }
                          
                          input{
                              width: 100%;
                              height: 100%;
                              border-radius: 16px;
                              padding-left: 25%;
                          }
                          .search-suggestion-wrapper {
                              position: absolute;
                              top: 100%;
                              left: 0;
                              border-radius: 12px;
                              background-color: white;
                              padding: 24px 24px 0;
                              box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
                              width: 100%;
                              max-height: 291px;
                              height: fit-content;
                              overflow-y: auto;
                              z-index: 99;
                              scrollbar-width: thin;
                              scrollbar-color: #22BBB0 #f1f1f1;
                            }
  
                      }
                      .destination-input-bar{
                        border: 1px solid #0000001a;
                          position: relative;
                          width: 46%;
                          height: 60px;
                          border-radius: 16px;
                          .location {
                            position: absolute;
                            //left: 15px;
                            width: 25px;
                            font-family: 'Open Sans', sans-serif;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: #1E1E26;
                            left:15px;
                            top:15px;
                          }
                          input{
                              width: 100%;
                              height: 100%;
                              border-radius: 16px;
                              padding-left: 25%;
                          }
                          .search-suggestion-wrapper {
                              position: absolute;
                              top: 100%;
                              left: 0;
                              border-radius: 12px;
                              background-color: white;
                              padding: 24px 24px 0;
                              box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
                              width: 100%;
                              max-height: 291px;
                              height: fit-content;
                              overflow-y: auto;
                              z-index: 99;
                              scrollbar-width: thin;
                              scrollbar-color: #22BBB0 #f1f1f1;
                            }
  
                      }
                      
                  }
                  .date {
                      background: #FFFFFF;
                      border: 1px solid rgba(0, 0, 0, 0.1);
                      border-radius: 12px;
                      display: flex;
                      position: relative;
                      height: 60px;
                      align-items: center;
                      padding: 0px 5px;
                      .custome-height {
                        display: flex;
                        height: 24px;
                        position: relative;
                        padding: 22px 16px 22px 16px; 
                        align-items: center;
                      }
                      .cal-date{
                        color: #FFFFFF;
                        background-color: #22BCB1;
                        height: 24px;
                        border-radius: 8px;
                        
                      }
                
                      .today, .Tomorrow {
                        font-family: 'Open Sans', sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 22px;
                        color: rgba(30, 30, 38, 0.6);
                        cursor: pointer;
                        width: 48px;
                        height: 48px;
                        border-radius: 8px;
                        text-align: center;
  
                        .day{
                          width: auto;
                          margin: auto;
                          height: 18px;
                        } 
                        .dated{
                          width: auto;
                          margin: auto;
                          height: 24px;
                        }
                      }
                
                      .selected-date {
                        color: #FFFFFF;
                        background-color: #22BCB1;
                      }
                
                      .Calendar-icon {
                        position: absolute;
                        left: 15px;
                        width: 25px;
                        font-family: 'Open Sans', sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: #1E1E26;
                      }
                
                      .fast-date-select {
                        display: flex;
                        gap: 12px;
                        height: 100%;
                        padding: 3px 12px 3px 12px;
                        align-items: center;
                        
                      }
                
                      .date-value {
                        margin-left: 30px;
                      }
                
                      .calender {
                        width: 100px;
                        margin-left: 26px;
                        border: none;
                        border-right: 1.5px solid rgba(0, 0, 0, 0.1);
                        //opacity: 0.1;
                        height: 24px;
                        outline: none;
                        cursor: pointer;
                      }
  
                    .holiday-highlight {
                      color: #FFDD00;
                      transition: all 0.3s ease-in-out;
                      :hover{
                        color: #FFFF;
                      }
                    }
  
                    .react-calendar__tile:hover {
                      background-color: #22BCB1 !important; 
                      color: #FFFFFF !important;
                    }
                    .react-calendar__tile.holiday-highlight:hover {
                      background-color: #22BCB1 !important; 
                      color: #FFFFFF !important; 
                    }
                      .calender-selected{
                        width: 100px;
                        margin-left: 26px;
                        border: none;
                        border-right: 1.5px solid rgba(0, 0, 0, 0.1);
                        //opacity: 0.1;
                        height: 24px;
                        outline: none;
                        cursor: pointer;
                        color: #FFFFFF;
                      }
  
                      .calendar-wrapper .react-calendar .react-calendar__navigation {
                        margin-bottom: 0px !important;
                        height: 26px !important;
                      }
                      .calendar-wrapper .react-calendar {
                        border-radius: 16px 16px 0 0 !important;
                      }
  
                      .selected-holiday {
                        color: #FFFF !important;
                      }
                      .calendar-wrapper .react-calendar .react-calendar__month-view__weekdays {
                        margin-bottom: 0 !important;
                      }
  
                      .Holidays {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 3px;
                        padding: 8px;
                        list-style: none;
                        background: #F8F8F9;
                        border-radius: 0 0 16px 16px;
                        border: 1px solid #D2D2D4;                  
                       li {
                        display: flex;
                        flex-direction: row;
                        gap: 2px;
                        align-items: center;
                        .holiday-info{
                          color: #1E1E26;;
                          font-family: 'Open Sans';
                          font-size: 10px;
                          font-weight: 400;
                          line-height: 18px;
                          text-align: left;
                        }
                      }
                    }
                      
                    }
  
  
  
              }
              .homepage-d-search-button{
                width:25%;
                // height:100%;
                padding:16px;
                background: #1E1E26;
                color:white;
                font-family: 'Open Sans';
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                border-radius: 0px 12px 12px 0px;
      
                .Search {
                  position: relative;
                  left: -5px;
                  width: 20px;
                }
              }
             
  
          }

        }
            
       
      }
}